:root {

    /*
    @media (prefers-color-scheme: dark) {
        // colors
        --c-core-lead-1           : #36aef8;
        --c-core-lead-2           : #2d98da;
        --c-core-lead             : #3867d6;
        --c-core-lead-4           : #2754be;
        --c-core-lead-5           : #244daf;
        --c-core-alt-2            : #26de81;
        --c-core-alt              : #07a652;
        --c-core-alt-4            : #068d46;
        --c-core-inverse          : #faf9f6;
        --c-core-bg-2             : #333333;
        --c-core-bg               : #222222;
        --c-core-bg-4             : #111111;
        --c-core-bg-5             : #000000;
        --c-text                  : #faf9f6;
        --c-comp-lead             : #8854d0;
        --c-comp-lead-2           : #a55eea;
        --c-comp-lead-4           : #7136c3;
        --c-comp-alt              : #fa8231;
        --c-comp-alt-2            : #fd9644;
        --c-comp-alt-4            : #f86806;
        --c-gray-1                : #1A1A1A;
        --c-gray-2                : #333333;
        --c-gray                  : #4D4D4D;
        --c-gray-3                : var(--c-gray);
        --c-gray-4                : #666666;
        --c-gray-5                : #7F7F7F;
        --c-success-1             : #6DFFB8;
        --c-success-2             : #3ad985;
        --c-success               : #07a652;
        --c-success-4             : #068d46;
        --c-success-5             : #00731F;
        --c-success-inverse       : var(--c-bg);
        --c-warning               : #ee8735;
        --c-warning-inverse       : var(--c-bg);
        --c-danger                : #eb3b5a;
        --c-danger-inverse        : var(--c-bg);
        --c-error                 : var(--c-danger);
        --c-error-inverse         : var(--c-danger-inverse);
    }
    */

    // global prefix for all the classes
    --global-prefix           : "";

    // font sizes
    --base-font-size          : 1px;

    --font-min                : calc(10 * var(--base-font-size));
    --font-xs                 : calc(12 * var(--base-font-size));
    --font-s                  : calc(14 * var(--base-font-size));
    --font-m                  : calc(16 * var(--base-font-size));
    --font-l                  : calc(18 * var(--base-font-size));
    --font-xl                 : calc(20 * var(--base-font-size));
    --font-2xl                : calc(25 * var(--base-font-size));
    --font-3xl                : calc(32 * var(--base-font-size));
    --font-max                : calc(38 * var(--base-font-size));

    // margins
    --base-margin             : 1px;
    --margin-min              : calc(1 * var(--base-margin));
    --margin-xs               : calc(2 * var(--base-margin));
    --margin-s                : calc(5 * var(--base-margin));
    --margin-m                : calc(10 * var(--base-margin));
    --margin                  : var(--margin-m);
    --margin-l                : calc(20 * var(--base-margin));
    --margin-xl               : calc(30 * var(--base-margin));
    --margin-2xl              : calc(45 * var(--base-margin));
    --margin-max              : calc(60 * var(--base-margin));

    // line heights
    --line-height-075         : 0.75;
    --line-height-080         : 0.80;
    --line-height-085         : 0.85;
    --line-height-090         : 0.90;
    --line-height-100         : 1.00;
    --line-height-110         : 1.10;
    --line-height-120         : 1.20;
    --line-height-130         : 1.30;
    --line-height-140         : 1.40;
    --line-height-150         : 1.50;
    --line-height-175         : 1.75;
    --line-height-200         : 2.00;

    // colors
    --c-core-lead-1           : #6d63c9;
    --c-core-lead-2           : #4b40b9;
    --c-core-lead             : #3C3393;
    --c-core-lead-3           : #3C3393;
    --c-core-lead-4           : #2d266d;
    --c-core-lead-5           : #1d1947;
    --c-core-alt-1            : #ffc9c1;
    --c-core-alt-2            : #ff9d8e;
    --c-core-alt              : #FF715B;
    --c-core-alt-3            : #FF715B;
    --c-core-alt-4            : #8e1300;
    --c-core-alt-5            : #5b0c00;
    --c-core-inverse          : #222222;
    --c-core-bg-1             : #ffffff;
    --c-core-bg-2             : #ffffff;
    --c-core-bg               : #ffffff;
    --c-core-bg-4             : #faf9f6;
    --c-core-bg-5             : #edece9;
    --c-text                  : #2a2a2a;
    --c-comp-lead-1           : #4ddfcc;
    --c-comp-lead-2           : #26d3bd;
    --c-comp-lead             : #1EA896;
    --c-comp-lead-4           : #167d6f;
    --c-comp-lead-5           : #0f5149;
    --c-comp-alt-1            : #824557;
    --c-comp-alt-2            : #3f222a;
    --c-comp-alt              : #1e1014;
    --c-comp-alt-4            : #1e1014;
    --c-comp-alt-5            : #000;
    --c-gray-1                : #f2f2f2;
    --c-gray-2                : #d1d8e0;
    --c-gray                  : #a5b1c2;
    --c-gray-3                : var(--c-gray);
    --c-gray-4                : #778ca3;
    --c-gray-5                : #393946;
    --c-success-1             : #6dffb8;
    --c-success-2             : #3ad985;
    --c-success               : #07a652;
    --c-success-4             : #068d46;
    --c-success-5             : #00731f;
    --c-success-inverse       : var(--c-bg);
    --c-warning               : #ee8735;
    --c-warning-inverse       : var(--c-bg);
    --c-danger                : #eb3b5a;
    --c-danger-inverse        : var(--c-bg);
    --c-error                 : var(--c-danger);
    --c-error-inverse         : var(--c-danger-inverse);

    // color aliases
    --c-lead                  : var(--c-core-lead);
    --c-lead-1                : var(--c-core-lead-1);
    --c-lead-2                : var(--c-core-lead-2);
    --c-lead-4                : var(--c-core-lead-4);
    --c-lead-5                : var(--c-core-lead-5);
    --c-alt-1                 : var(--c-core-alt-1);
    --c-alt-2                 : var(--c-core-alt-2);
    --c-alt                   : var(--c-core-alt);
    --c-alt-4                 : var(--c-core-alt-4);
    --c-alt-5                 : var(--c-core-alt-5);
    --c-inverse               : var(--c-core-inverse);
    --c-bg-4                  : var(--c-core-bg-4);
    --c-bg-5                  : var(--c-core-bg-5);
    --c-bg                    : var(--c-core-bg);
    --c-bg-2                  : var(--c-core-bg-2);
    --c-bg-1                  : var(--c-core-bg-1);

    //shades
    --c-shade-1               : rgba(0, 0, 0, 0.1);
    --c-shade-2               : rgba(0, 0, 0, 0.2);
    --c-shade-3               : rgba(0, 0, 0, 0.4);
    --c-shade                 : var(--c-shade-3);
    --c-shade-4               : rgba(0, 0, 0, 0.6);
    --c-shade-5               : rgba(0, 0, 0, 0.8);

    // barnd colors
    --c-facebook              : #4867aa;

    // box shadow

    --box-shadow-1            : 0 2px 8px rgba(0, 0, 0, 0.08);
    --box-shadow-2            : 0 5px 15px rgba(0, 0, 0, 0.08);
    --box-shadow-3            : 0 8px 22px rgba(0, 0, 0, 0.12);
    --box-shadow-4            : 0 14px 25px rgba(0, 0, 0, 0.16);
    --box-shadow-5            : 0 28px 50px rgba(0, 0, 0, 0.16);
    --box-shadow              : var(--box-shadow-3);

    // containers
    --container-gutter        : calc(20 * var(--base-margin));

    --container-xs-width-seed : calc(400 * var(--base-margin));
    --container-s-width-seed  : calc(800 * var(--base-margin));
    --container-m-width-seed  : calc(1124 * var(--base-margin));
    --container-l-width-seed  : calc(1300 * var(--base-margin));
    --container-xs            : calc(
            var(--container-xs-width-seed) + (2 * var(--container-gutter))
    );
    --container-s             : calc(
            var(--container-s-width-seed) + (2 * var(--container-gutter))
    );
    --container-m             : calc(
            var(--container-m-width-seed) + (2 * var(--container-gutter))
    );
    --container               : var(--container-m);
    --container-l             : calc(
            var(--container-l-width-seed) + (2 * var(--container-gutter))
    );

    --border-radius-none      : 0;
    --border-radius-sharp     : calc(3 * var(--base-margin));
    --border-radius-s         : var(--border-radius-sharp);
    --border-radius           : calc(20 * var(--base-margin));
    --border-radius-m         : var(--border-radius);
    --border-radius-l         : calc(35 * var(--base-margin));
    --border-radius-round     : 50%;

    --z-0                     : 0;
    --z-1                     : 1;
    --z-2                     : 2;
    --z-3                     : 3;
    --z-4                     : 4;
    --z-5                     : 5;
    --z-top-1                 : 1000;
    --z-top-2                 : 1001;
    --z-top-3                 : 1002;
    --z-top-4                 : 1003;
    --z-top-5                 : 1004;

    --width-50                : 50px;
    --width-100               : 100px;
    --width-150               : 150px;
    --width-200               : 200px;
    --width-250               : 250px;
    --width-300               : 300px;
    --width-350               : 350px;
    --width-400               : 400px;
    --width-450               : 450px;
    --width-500               : 500px;
    --width-550               : 550px;
    --width-600               : 600px;
    --width-700               : 700px;
    --width-800               : 800px;

    --height-50               : 50px;
    --height-100              : 100px;
    --height-150              : 150px;
    --height-200              : 200px;
    --height-250              : 250px;
    --height-300              : 300px;
    --height-350              : 350px;
    --height-400              : 400px;
    --height-500              : 500px;
    --height-600              : 600px;
    --height-700              : 700px;
    --height-800              : 800px;
    // --button-border-radius       : var(--border-radius);
    //--button-border-radius-round : var(--border-radius-round);
    //--button-border-radius-sharp : var(--border-radius-sharp);
}

// global prefix
$global-prefix             : "we-" !default;
$prefix                    : $global-prefix !default;

// margin scss vars
$margin-base               : var(--base-margin) !default;
$base-margin               : $margin-base !default;
$margin-min                : var(--margin-min) !default;
$margin-xs                 : var(--margin-xs) !default;
$margin-s                  : var(--margin-s) !default;
$margin-m                  : var(--margin-m) !default;
$margin                    : $margin-m !default;
$margin-l                  : var(--margin-l) !default;
$margin-xl                 : var(--margin-xl) !default;
$margin-2xl                : var(--margin-2xl) !default;
$margin-max                : var(--margin-max) !default;

$margin-map                : (
        "-min": $margin-min,
        "-xs": $margin-xs,
        "-s": $margin-s,
        "-small": $margin-s,
        "-m": $margin-m,
        "-medium": $margin-m,
        "": $margin,
        "-l": $margin-l,
        "-large": $margin-l,
        "-xl": $margin-xl,
        "-2xl": $margin-2xl,
        "-max": $margin-max,
        "-auto": auto,
        "-remove" : 0,
) !default;

// line height
$line-height-075           : var(--line-height-075) !default;
$line-height-080           : var(--line-height-080) !default;
$line-height-085           : var(--line-height-085) !default;
$line-height-090           : var(--line-height-090) !default;
$line-height-100           : var(--line-height-100) !default;
$line-height-110           : var(--line-height-110) !default;
$line-height-120           : var(--line-height-120) !default;
$line-height-130           : var(--line-height-130) !default;
$line-height-140           : var(--line-height-140) !default;
$line-height-150           : var(--line-height-150) !default;
$line-height-175           : var(--line-height-175) !default;
$line-height-200           : var(--line-height-200) !default;

$line-height-map           : (
        "-075": $line-height-075,
        "-080": $line-height-080,
        "-085": $line-height-085,
        "-090": $line-height-090,
        "-100": $line-height-100,
        "-110": $line-height-110,
        "-120": $line-height-120,
        "-130": $line-height-130,
        "-140": $line-height-140,
        "-150": $line-height-150,
        "-175": $line-height-175,
        "-200": $line-height-200,
) !default;

// heights
$height-50                 : var(--height-50);
$height-100                : var(--height-100);
$height-150                : var(--height-150);
$height-200                : var(--height-200);
$height-250                : var(--height-250);
$height-300                : var(--height-300);
$height-350                : var(--height-350);
$height-400                : var(--height-400);
$height-500                : var(--height-500);
$height-600                : var(--height-600);
$height-700                : var(--height-700);
$height-800                : var(--height-800);

// widths
$width-50                  : var(--width-50);
$width-100                 : var(--width-100);
$width-150                 : var(--width-150);
$width-200                 : var(--width-200);
$width-250                 : var(--width-250);
$width-300                 : var(--width-300);
$width-350                 : var(--width-350);
$width-400                 : var(--width-400);
$width-450                 : var(--width-450);
$width-500                 : var(--width-500);
$width-550                 : var(--width-550);
$width-600                 : var(--width-600);
$width-700                 : var(--width-700);
$width-800                 : var(--width-800);


$z-0                       : var(--z-0);
$z-1                       : var(--z-1);
$z-2                       : var(--z-2);
$z-3                       : var(--z-3);
$z-4                       : var(--z-4);
$z-5                       : var(--z-5);
$z-top-1                   : var(--z-top-1);
$z-top-2                   : var(--z-top-2);
$z-top-3                   : var(--z-top-3);
$z-top-4                   : var(--z-top-4);
$z-top-5                   : var(--z-top-5);

// border radius
$border-radius-none        : var(--border-radius-none) !default;
$border-radius-sharp       : var(--border-radius-sharp) !default;
$border-radius-s           : var(--border-radius-s) !default;
$border-radius             : var(--border-radius) !default;
$border-radius-m           : var(--border-radius-m) !default;
$border-radius-l           : var(--border-radius-l) !default;
$border-radius-round       : var(--border-radius-round) !default;


// color scss vars
$color-core-lead-1         : var(--c-core-lead-1) !default;
$color-core-lead-2         : var(--c-core-lead-2) !default;
$color-core-lead           : var(--c-core-lead) !default;
$color-core-lead-3         : var(--c-core-lead) !default;
$color-core-lead-4         : var(--c-core-lead-4) !default;
$color-core-lead-5         : var(--c-core-lead-5) !default;
$color-core-alt-1          : var(--c-core-alt-1) !default;
$color-core-alt-2          : var(--c-core-alt-2) !default;
$color-core-alt            : var(--c-core-alt) !default;
$color-core-alt-3          : var(--c-core-alt) !default;
$color-core-alt-4          : var(--c-core-alt-4) !default;
$color-core-alt-5          : var(--c-core-alt-5) !default;
$color-core-inverse        : var(--c-core-inverse) !default;
$color-core-bg-1           : var(--c-core-bg-1) !default;
$color-core-bg-2           : var(--c-core-bg-2) !default;
$color-core-bg-3           : var(--c-core-bg) !default;
$color-core-bg             : var(--c-core-bg) !default;
$color-core-bg-4           : var(--c-core-bg-4) !default;
$color-core-bg-5           : var(--c-core-bg-5) !default;
$color-lead                : var(--c-lead) !default;
$color-lead-1              : var(--c-lead-1) !default;
$color-lead-2              : var(--c-lead-2) !default;
$color-lead                : var(--c-lead) !default;
$color-lead-4              : var(--c-lead-4) !default;
$color-lead-5              : var(--c-lead-5) !default;
$color-alt-1               : var(--c-alt-1) !default;
$color-alt-2               : var(--c-alt-2) !default;
$color-alt                 : var(--c-alt) !default;
$color-alt-3               : var(--c-alt) !default;
$color-alt-4               : var(--c-alt-4) !default;
$color-alt-5               : var(--c-alt-5) !default;
$color-inverse             : var(--c-inverse) !default;
$color-bg-1                : var(--c-bg-1) !default;
$color-bg-2                : var(--c-bg-2) !default;
$color-bg                  : var(--c-bg) !default;
$color-bg-3                : var(--c-bg) !default;
$color-bg-4                : var(--c-bg-4) !default;
$color-bg-5                : var(--c-bg-5) !default;
$color-text                : var(--c-text) !default;
$color-comp-lead-1         : var(--c-comp-lead-1) !default;
$color-comp-lead-2         : var(--c-comp-lead-2) !default;
$color-comp-lead           : var(--c-comp-lead) !default;
$color-comp-lead-3         : var(--c-comp-lead) !default;
$color-comp-lead-4         : var(--c-comp-lead-4) !default;
$color-comp-lead-5         : var(--c-comp-lead-5) !default;
$color-comp-alt-1          : var(--c-comp-alt-1) !default;
$color-comp-alt-2          : var(--c-comp-alt-2) !default;
$color-comp-alt            : var(--c-comp-alt) !default;
$color-comp-alt-3          : var(--c-comp-alt) !default;
$color-comp-alt-4          : var(--c-comp-alt-4) !default;
$color-comp-alt-5          : var(--c-comp-alt-5) !default;
$color-gray-1              : var(--c-gray-1) !default;
$color-gray-2              : var(--c-gray-2) !default;
$color-gray                : var(--c-gray) !default;
$color-gray-3              : var(--c-gray) !default;
$color-gray-4              : var(--c-gray-4) !default;
$color-gray-5              : var(--c-gray-5) !default;
$color-success-1           : var(--c-success-1) !default;
$color-success-2           : var(--c-success-2) !default;
$color-success             : var(--c-success) !default;
$color-success-3           : var(--c-success) !default;
$color-success-4           : var(--c-success-4) !default;
$color-success-5           : var(--c-success-5) !default;
$color-success-inverse     : var(--c-success-inverse) !default;
$color-warning             : var(--c-warning) !default;
$color-warning-inverse     : var(--c-warning-inverse) !default;
$color-danger              : var(--c-danger) !default;
$color-danger-inverse      : var(--c-danger-inverse) !default;
$color-error               : var(--c-error) !default;
$color-error-inverse       : var(--c-error-inverse) !default;

$color-shade-1             : var(--c-shade-1);
$color-shade-2             : var(--c-shade-2);
$color-shade-3             : var(--c-shade-3);
$color-shade               : var(--c-shade);
$color-shade-4             : var(--c-shade-4);
$color-shade-5             : var(--c-shade-5);

// box shadow scss vars
$box-shadow-1              : var(--box-shadow-1) !default;
$box-shadow-2              : var(--box-shadow-2) !default;
$box-shadow-3              : var(--box-shadow-3) !default;
$box-shadow                : var(--box-shadow) !default;
$box-shadow-4              : var(--box-shadow-4) !default;
$box-shadow-5              : var(--box-shadow-5) !default;

$gradient-1                : linear-gradient(
                180deg,
                rgba(255, 255, 255, 0) 9.65%,
                #ffffff 70.12%
),
linear-gradient(20.35deg, #ffffff 45.49%, rgba(255, 255, 255, 0) 73.38%) !default;
$gradient-5                : linear-gradient(
                to top,
                #000000,
                rgba(255, 255, 255, 0),
                #ffffff
) !default;
$gradient-complex          : linear-gradient(
                148.01deg,
                #000000 -12.1%,
                rgba(0, 0, 0, 0) 32.56%
),
linear-gradient(164.53deg, rgba(0, 0, 0, 0) 45.91%, #000000 87.41%) !default;
$gradiant-diagonal         : linear-gradient(to top, #000000, rgba(0, 0, 0, 0)),
linear-gradient(to top, rgba(0, 0, 0, 0), #000000) !default;


$screen-s-min              : 500px !default;
$screen-s-max              : 499px !default;
$screen-m-min              : 768px !default;
$screen-m-max              : 767px !default;
$screen-l-min              : 1025px !default;
$screen-l-max              : 1024px !default;
$screen-xl-min             : 1300px !default;
$screen-xl-max             : 20000px !default;

$break-s                   : $screen-s-max;
$break-m                   : $screen-m-max;
$break-l                   : $screen-l-max;
$break-xl                  : $screen-xl-max;

$font-base-size            : var(--base-font-size) !default;
$font-min                  : var(--font-min) !default;
$font-2xs                  : var(--font-2xs) !default;
$font-xs                   : var(--font-xs) !default;
$font-s                    : var(--font-s) !default;
$font-m                    : var(--font-m) !default;
$font-l                    : var(--font-l) !default;
$font-xl                   : var(--font-xl) !default;
$font-2xl                  : var(--font-2xl) !default;
$font-3xl                  : var(--font-3xl) !default;
$font-max                  : var(--font-max) !default;

$font-size-map             : (
        "-min": $font-min,
        "-xs": $font-xs,
        "-s": $font-s,
        "-m": $font-m,
        "-l": $font-l,
        "-xl": $font-xl,
        "-2xl": $font-2xl,
        "-3xl": $font-3xl,
        "-max": $font-max,
) !default;

$container-gutter          : var(--container-gutter) !default;
$container-xs              : var(--container-xs) !default;
$container-s               : var(--container-s) !default;
$container-m               : var(--container-m) !default;
$container                 : var(--container-m) !default;
$container-l               : var(--container-l) !default;
$container-xs-seed              : var(--container-xs-width-seed) !default;
$container-s-seed               : var(--container-s-width-seed) !default;
$container-m-seed               : var(--container-m-width-seed) !default;
$container-seed                  : var(--container-m-width-seed) !default;
$container-l-seed                : var(--container-l-width-seed) !default;



$max-width-map             : (
        "-50": $width-50,
        "-100": $width-100,
        "-150": $width-150,
        "-200": $width-200,
        "-250": $width-250,
        "-300": $width-300,
        "-350": $width-350,
        "-400": $width-400,
        "-450": $width-450,
        "-500": $width-500,
        "-550": $width-550,
        "-600": $width-600,
        "-700": $width-700,
        "-800": $width-800,

        "-xs": $container-xs,
        "-s": $container-s,
        "-m": $container-m,
        "-l": $container-l,
        "-xs-seed": $container-xs-seed,
        "-s-seed": $container-s-seed,
        "-m-seed": $container-m-seed,
        "-l-seed": $container-l-seed,
        "-none": none,
) !default;

$min-width-map             : (
        "-50": $width-50,
        "-100": $width-100,
        "-150": $width-150,
        "-200": $width-200,
        "-250": $width-250,
        "-300": $width-300,
        "-350": $width-350,
        "-400": $width-400,
        "-500": $width-500,
        "-600": $width-600,
        "-700": $width-700,
        "-800": $width-800,
) !default;

$min-height-map            : (
        "-50": $height-50,
        "-100": $height-100,
        "-150": $height-150,
        "-200": $height-200,
        "-250": $height-250,
        "-300": $height-300,
        "-350": $height-350,
        "-400": $height-400,
        "-500": $height-500,
        "-600": $height-600,
        "-700": $height-700,
        "-800": $height-800,
) !default;

$width-map                 : (
        "-50": $width-50,
        "-100": $width-100,
        "-150": $width-150,
        "-200": $width-200,
        "-250": $width-250,
        "-300": $width-300,
        "-350": $width-350,
        "-400": $width-400,
        "-500": $width-500,
        "-600": $width-600,
        "-700": $width-700,
        "-800": $width-800,
) !default;

$z-map                     : (
        "-0": $z-0,
        "-1": $z-1,
        "-2": $z-2,
        "-3": $z-3,
        "-4": $z-4,
        "-5": $z-5,
        "-top-1": $z-top-1,
        "-top-2": $z-top-2,
        "-top-3": $z-top-3,
        "-top-4": $z-top-4,
        "-top-5": $z-top-5,
) !default;

$color-map                 : (
        "-core-lead-1": $color-core-lead-1,
        "-core-lead-2": $color-core-lead-2,
        "-core-lead": $color-core-lead,
        "-core-lead-4": $color-core-lead-4,
        "-core-lead-5": $color-core-lead-5,
        "-core-alt-1": $color-core-alt-1,
        "-core-alt-2": $color-core-alt-2,
        "-core-alt": $color-core-alt,
        "-core-alt-4": $color-core-alt-2,
        "-core-alt-5": $color-core-alt-5,
        "-core-inverse": $color-core-inverse,
        "-core-bg-1": $color-core-bg-1,
        "-core-bg-2": $color-core-bg-2,
        "-core-bg": $color-core-bg,
        "-core-bg-4": $color-core-bg-4,
        "-core-bg-5": $color-core-bg-5,
        "-lead-1": $color-lead-1,
        "-lead-2": $color-lead-2,
        "-lead": $color-lead,
        "-lead-4": $color-lead-4,
        "-lead-5": $color-lead-5,
        "-alt-1": $color-alt-1,
        "-alt-2": $color-alt-2,
        "-alt": $color-alt,
        "-alt-4": $color-alt-4,
        "-alt-5": $color-alt-5,
        "-inverse": $color-inverse,
        "-bg-5": $color-bg-5,
        "-bg-4": $color-bg-4,
        "-bg": $color-bg,
        "-bg-2": $color-bg-2,
        "-bg-1": $color-bg-1,
        "-text": $color-text,
        "-comp-lead-1": $color-comp-lead-1,
        "-comp-lead-2": $color-comp-lead-2,
        "-comp-lead": $color-comp-lead,
        "-comp-lead-4": $color-comp-lead-4,
        "-comp-lead-5": $color-comp-lead-5,
        "-comp-alt-1": $color-comp-alt-1,
        "-comp-alt-2": $color-comp-alt-2,
        "-comp-alt": $color-comp-alt,
        "-comp-alt-4": $color-comp-alt-4,
        "-comp-alt-5": $color-comp-alt-5,
        "-gray-1": $color-gray-1,
        "-gray-2": $color-gray-2,
        "-gray": $color-gray,
        "-gray-4": $color-gray-4,
        "-gray-5": $color-gray-5,
        "-success-1": $color-success-1,
        "-success-2": $color-success-2,
        "-success": $color-success,
        "-success-4": $color-success-4,
        "-success-5": $color-success-5,
        "-success-inverse": $color-success-inverse,
        "-warning": $color-warning,
        "-warning-inverse": $color-warning-inverse,
        "-danger": $color-danger,
        "-danger-inverse": $color-danger-inverse,
        "-error-inverse": $color-error-inverse,
        "-error": $color-error,
) !default;

$grayscale-map             : (
        "-gray-1": $color-gray-1,
        "-gray-2": $color-gray-2,
        "-gray": $color-gray,
        "-gray-3": $color-gray,
        "-gray-5": $color-gray-5,
        "-gray-4": $color-gray-4,
) !default;

$shade-map                 : (
        "-1": $color-shade-1,
        "-2": $color-shade-2,
        "": $color-shade,
        "-3": $color-shade,
        "-4": $color-shade-4,
        "-5": $color-shade-5,
) !default;

$box-shadow-map            : (
        "-1": $box-shadow-1,
        "-2": $box-shadow-2,
        "-3": $box-shadow-3,
        "": $box-shadow,
        "-4": $box-shadow-4,
        "-5": $box-shadow-5,
) !default;

$gradient-map              : (
        "-1": $gradient-1,
        "-5": $gradient-5,
        "-complex": $gradient-complex,
        "-diagonal": $gradiant-diagonal,
) !default;

$break-points-map          : (
        "-s": $screen-s-max,
        "-m": $screen-m-max,
        "-l": $screen-l-max,
        "-xl": $screen-xl-min,
) !default;

$break-points-map-readable : (
        "s": $screen-s-max,
        "m": $screen-m-max,
        "l": $screen-l-max,
        "xl": $screen-xl-min,
) !default;

$container-map             : (
        "-xs": $container-xs,
        "-s": $container-s,
        "-m": $container-m,
    // '' : $container-m,
        "-l": $container-l,
) !default;

$radius-map                : (
        "-remove": $border-radius-none,
        "-none": $border-radius-none,
        "-sharp": $border-radius-sharp,
        "-s": $border-radius-sharp,
        "": $border-radius,
        "-m": $border-radius,
        "-l": $border-radius-l,
        "-round": $border-radius-round,
) !default;

$border-thickness-map      : (
        "-thin": 1px,
        "-xs": 1px,
        "-s": 2px,
        "": 3px,
        "-medium": 3px,
        "-m": 3px,
        "-thick": 6px,
        "-l": 6px,
) !default;

// transition
$_transition-map           : (
        '-slow' : 450ms,
        '' : 250ms,
        '-fast' : 150ms,
);

@function removeMapKeysLeadingDash($map) {
    $new-map : ();

    @each $key, $value in $map {
        $new-key : $key;

        @if str-slice($key, 1, 1) == "-" {
            $new-key : str-slice($key, 2);
        }

        $new-map : map-merge($new-map, ($new-key: $value));
    }

    @return $new-map;
}

$utilities                 : (
        "opacity": (
            // can be omitted if matches key
                property: "opacity",
            // can be omitted
            // optional prefix
            //   classPrefix: "flex-",
            // class name to use instead of map key
            // className: "margin",
            // suffix for the class name
            //  classSuffix: "-horizontal",
                responsive: true,
            // mobileFirstResponsive responsive classes are mobile first using min-width. if false, they are desktop first using max-width
            // mobileFirstResponsive: true,
                states: hover focus,
                important: true,
            // will yield 0-opacity, instead of opacity-0
            // invertedValueKeyOrder: true,
            // rules added to ANY class generated by this util
            //   fixedRules: (
            //          'max-width' : 100%,
            //  ),
            // vlaue prefix will be added before the value. so this will yield 1px solid 0, etc
            //valuePrefix: "1px solid ",
            // vlaue suffix will be added after the value. so this will yield 0 1px solid, etc
            //valuePrefix: "1px solid ",
            //
            // supports also 0 25 50 as a list
            // empty keys allowed ONCE i.e. "": 0 -> .opacity{opacity:0;}
                values: (
                        0: 0,
                        25: .25,
                        50: .5,
                        75: .75,
                        100: 1,
                )
        ),
        "flex": (
                responsive: true,
                important: false,
            // prop:value or key : (prop:value,prop2:value2)...
                values: (
                        'row': (
                                display: "flex",
                                flex-direction: row,
                        ),
                        'column': (
                                display: "flex",
                                flex-direction: column,
                        ),
                )
        ),
        "max-width": (
                property: "max-width",
                important: true,
                responsive: true,
                values: removeMapKeysLeadingDash($max-width-map)
        ),
        "min-width": (
                property: "min-width",
                important: true,
                values: removeMapKeysLeadingDash($max-width-map)
        ),
        "min-height": (
                property: "min-height",
                important: true,
                values: removeMapKeysLeadingDash($min-height-map)
        ),
        "width": (
                property: "width",
                important: true,
                states: hover focus,
                fixedRules: (
                        'max-width' : 100%,
                ),
                values: removeMapKeysLeadingDash($width-map)
        ),
        "margin": (
                property: "margin",
                important: true,
                responsive: true,
                values: removeMapKeysLeadingDash($margin-map)
        ),
        "margin-horizontal": (
                property: "margin-inline",
                className: "margin",
                classSuffix: "-horizontal",
                important: true,
                responsive: true,
                invertedValueKeyOrder: true,
                values: removeMapKeysLeadingDash($margin-map)
        ),
        "margin-inline": (
                property: "margin-inline",
                className: "margin",
                classSuffix: "-inline",
                important: true,
                responsive: true,
                invertedValueKeyOrder: true,
                values: removeMapKeysLeadingDash($margin-map)
        ),
        "margin-vertical": (
                property: "margin-block",
                className: "margin",
                classSuffix: "-vertical",
                important: true,
                responsive: true,
                invertedValueKeyOrder: true,
                values: removeMapKeysLeadingDash($margin-map)
        ),
        "display-block": (
                property: "display",
                class: "display",
                important: true,
                responsive: true,
                values: (
                        'block': block
                )
        ),
        "margin-block": (
                property: "margin-block",
                className: "margin",
                classSuffix: "-block",
                responsive: true,
                important: true,
                invertedValueKeyOrder: true,
                values: removeMapKeysLeadingDash($margin-map)
        ),
        "margin-inline-end": (
                property: "margin-inline-end",
                className: "margin",
                classSuffix: "-inline-end",
                responsive: true,
                important: true,
                invertedValueKeyOrder: true,
                values: removeMapKeysLeadingDash($margin-map)
        ),
        "margin-inline-start": (
                property: "margin-inline-start",
                className: "margin",
                classSuffix: "-inline-start",
                responsive: true,
                important: true,
                invertedValueKeyOrder: true,
                values: removeMapKeysLeadingDash($margin-map)
        ),
        "margin-top": (
                property: "margin-top",
                className: "margin",
                classSuffix: "-top",
                responsive: true,
                important: true,
                invertedValueKeyOrder: true,
                values: removeMapKeysLeadingDash($margin-map)
        ),
        "margin-bottom": (
                property: "margin-bottom",
                className: "margin",
                classSuffix: "-bottom",
                responsive: true,
                important: true,
                invertedValueKeyOrder: true,
                values: removeMapKeysLeadingDash($margin-map)
        ),
        "padding": (
                property: "padding",
                important: true,
                responsive: true,
                values: removeMapKeysLeadingDash($margin-map)
        ),
        "padding-horizontal": (
                property: "padding-inline",
                className: "padding",
                responsive: true,
                classSuffix: "-horizontal",
                important: true,
                invertedValueKeyOrder: true,
                values: removeMapKeysLeadingDash($margin-map)
        ),
        "padding-inline": (
                property: "padding-inline",
                className: "padding",
                responsive: true,
                classSuffix: "-inline",
                important: true,
                invertedValueKeyOrder: true,
                values: removeMapKeysLeadingDash($margin-map)
        ),
        "padding-vertical": (
                property: "padding-block",
                className: "padding",
                responsive: true,
                classSuffix: "-vertical",
                important: true,
                invertedValueKeyOrder: true,
                values: removeMapKeysLeadingDash($margin-map)
        ),
        "padding-block": (
                property: "padding-block",
                className: "padding",
                responsive: true,
                classSuffix: "-block",
                important: true,
                invertedValueKeyOrder: true,
                values: removeMapKeysLeadingDash($margin-map)
        ),
        "padding-inline-end": (
                property: "padding-inline-end",
                className: "padding",
                responsive: true,
                classSuffix: "-inline-end",
                important: true,
                invertedValueKeyOrder: true,
                values: removeMapKeysLeadingDash($margin-map)
        ),
        "padding-inline-start": (
                property: "padding-inline-start",
                className: "padding",
                responsive: true,
                classSuffix: "-inline-start",
                important: true,
                invertedValueKeyOrder: true,
                values: removeMapKeysLeadingDash($margin-map)
        ),
        "padding-top": (
                property: "padding-top",
                className: "padding",
                responsive: true,
                classSuffix: "-top",
                important: true,
                invertedValueKeyOrder: true,
                values: removeMapKeysLeadingDash($margin-map)
        ),
        "padding-bottom": (
                property: "padding-bottom",
                className: "padding",
                responsive: true,
                classSuffix: "-bottom",
                important: true,
                invertedValueKeyOrder: true,
                values: removeMapKeysLeadingDash($margin-map)
        ),
        "text-color-short" :(
                property: "color",
                className: "c",
                responsive: true,
                important: true,
                states: hover,
                values: removeMapKeysLeadingDash($color-map)
        ),
        "background-color-short" :(
                property: "background-color",
                className: "bg",
                responsive: true,
                important: true,
                states: hover,
                values: removeMapKeysLeadingDash($color-map)
        ),
        "background-color-full" :(
                property: "background-color",
                className: "background",
                important: true,
                states: hover,
                values: removeMapKeysLeadingDash($color-map)
        ),
        "thin-borders":(
                property: "border",
                className: "border",
                valuePrefix: "1px solid ",
                values: removeMapKeysLeadingDash($color-map)
        ),
        "shadow":(
                property: "box-shadow",
                values: removeMapKeysLeadingDash($box-shadow-map)
        ),
        "box-shadow":(
                property: "box-shadow",
                values: removeMapKeysLeadingDash($box-shadow-map)
        ),
        "z-effect":(
                property: "box-shadow",
                values: removeMapKeysLeadingDash($box-shadow-map)
        ),
        "box-shadow-inset":(
                property: "box-shadow",
                className: "box-shadow",
                invertedValueKeyOrder: true,
                classSuffix: "-inset",
                important: true,
                valueSuffix: " inset",
                values: removeMapKeysLeadingDash($box-shadow-map)
        ),
        "shader":(
                property: "background-color",
                values: removeMapKeysLeadingDash($shade-map)
        ),
        "shade":(
                property: "background-color",
                values: removeMapKeysLeadingDash($shade-map)
        ),
        "z-level" : (
                property: "z-index",
                values: removeMapKeysLeadingDash($z-map)
        ),
        "z-index" : (
                property: "z-index",
                values: removeMapKeysLeadingDash($z-map)
        ),
        "transition" : (
                values: removeMapKeysLeadingDash($_transition-map)
        ),
        "text-align": (
                className: "text",
                values: (
                        "center" : center,
                        "inline-start": start,
                        "start": start,
                        "inline-end": end,
                        "end": end,
                )
        ),
        "text-boldness-short": (
                className: "text",
                important: true,
                property: "font-weight",
                values: (
                        "bold": bold,
                        "100": 100,
                        "200": 200,
                        "300": 300,
                        "400": 400,
                        "500": 500,
                        "600": 600,
                        "700": 700,
                        "800": 800,
                        "900": 900,
                )
        ),
        "border-radius": (
                property: "border-radius",
                important: true,
                values: removeMapKeysLeadingDash($radius-map)
        ),
        "border-radius-strong": (
                className: "border-radius-strong",
                property: "border-radius",
                important: true,
                values: removeMapKeysLeadingDash($radius-map)
        ),
        "border-radius-short": (
                className: "radius",
                property: "border-radius",
                values: removeMapKeysLeadingDash($radius-map)
        ),
        "hidden": (
                responsive : true,
                important: true,
                className: "hidden",
                property: display,
                values: (
                        "" : none
                )
        ),
        "visible": (
                responsive : true,
                mobileFirstResponsive: false,
                important: true,
                className: "visible",
                property: display,
                values: (
                        "" : none
                )
        ),
        "text-size": (
                responsive: true,
                className: "text",
                property: "font-size",
                important: true,
                values: removeMapKeysLeadingDash($font-size-map),
        ),
        "line-height": (
                className: "line-height",
                responsive: true,
                property: "line-height",
            // important: true,
                values: removeMapKeysLeadingDash($line-height-map),
        ),
        "font-weight": (
                className: "text",
                important: true,
                responsive: true,
                property: "font-weight",
                values: (
                        "bold" : bold,
                        "bolder" : bolder,
                        "xbold": bolder,
                        "normal" : 400,
                        "lighter" : lighter,
                )
        ),
        "object-fit": (
                className: "object",
                values: (
                        "contain": contain,
                        "cover": cover,
                )
        ),
        "colored-border": (
                className: "border",
                property: "border-color",
                values: removeMapKeysLeadingDash($color-map)
        ),
        "border-thickness": (
                className: "border",
                property: "border-width",
                important: true,
                values: removeMapKeysLeadingDash($border-thickness-map)
        )
) !default;

