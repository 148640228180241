/**
 * Order is very important.
 * 1st: SCSS variable overrides
 * 2nd: build import
 * 3rd: component hooks
 * 4th: component import
 * 5th: CSS variables declaration/override (build includes :root level declaration)
 * 6th: other custom CSS
 */

// font
//@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Varela%20Round:wght@200;300;400;500;600;700;800&display=swap');

// alternative font: alef
//@import url('https://fonts.googleapis.com/css2?family=Alef:wght@200;300;400;500;600;700;800&display=swap');

//@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// 1st: SCSS variable overrides
$global-prefix : '';


// 2nd: build import
@import '../../../../../../otherSrc/client/we/scss/build';

// our custom component // * NOTE: we can not include partial SCSS files inside the client forlder, atm.

// 3rd: component hooks
//
// 4th: component import
@import '../../../../../../otherSrc/client/we/scss/components';



// 5th: CSS variables declaration/override
:root {

}

// 6th: other custom CSS
*,
*::before,
*::after {
    box-sizing: border-box;

}

// 6th: custom css
html, body  {
    background: var(--c-bg);

    &[lang=he] * {
        font-family: 'Rubik', 'Assistant', -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
    }

}
